// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/aboutUs.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-accessibility-accessibility-form-js": () => import("./../../../src/pages/accessibility/accessibilityForm.js" /* webpackChunkName: "component---src-pages-accessibility-accessibility-form-js" */),
  "component---src-pages-accessibility-accessibility-statement-js": () => import("./../../../src/pages/accessibility/accessibilityStatement.js" /* webpackChunkName: "component---src-pages-accessibility-accessibility-statement-js" */),
  "component---src-pages-accessibility-index-js": () => import("./../../../src/pages/accessibility/index.js" /* webpackChunkName: "component---src-pages-accessibility-index-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contactUs.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-gdpr-js": () => import("./../../../src/pages/gdpr.js" /* webpackChunkName: "component---src-pages-gdpr-js" */),
  "component---src-pages-inclusive-creation-js": () => import("./../../../src/pages/inclusiveCreation.js" /* webpackChunkName: "component---src-pages-inclusive-creation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partnerships-js": () => import("./../../../src/pages/partnerships.js" /* webpackChunkName: "component---src-pages-partnerships-js" */),
  "component---src-pages-workshops-js": () => import("./../../../src/pages/workshops.js" /* webpackChunkName: "component---src-pages-workshops-js" */)
}

